const orgConfig = () => [
  {
    navigation: {
      navItems: [
        {
          title: "Analytics",
          exact: true,
          disabled: false,
          to: { name: "Analytics" },
          icon: "mdi-view-dashboard",
        },
        {
          title: "Reports",
          exact: true,
          disabled: false,
          to: { name: "Reports" },
          icon: "mdi-file-chart",
        },
        {
          title: "Groups",
          exact: true,
          disabled: false,
          to: { name: "List", query: { level: "group", count: 0 } },
          icon: "mdi-account-group",
        },
        {
          title: "Leaderboard",
          exact: true,
          disabled: false,
          to: { name: "Leaderboard" },
          icon: "mdi-scoreboard",
        },
        {
          title: "Calendar",
          exact: true,
          disabled: false,
          to: { name: "Calendar" },
          icon: "mdi-calendar",
        },
        {
          title: "Products",
          exact: true,
          disabled: false,
          to: { name: "Products" },
          icon: "mdi-widgets",
        },
      ],
    },
    leaderboard: {
      headers: [
        {
          text: "Ranking",
          align: "center",
          sortable: false,
          value: "ranking",
        },
        { text: "Group", value: "name", width: 300 },
        {
          text: "Sales",
          align: "center",
          sortable: false,
          value: "sales",
        },
        {
          text: "Active Advisors",
          align: "center",
          sortable: false,
          value: "active_advisors",
        },
        {
          text: "New Prospects",
          align: "center",
          sortable: false,
          value: "new_prospects",
        },
        {
          text: "Total Prospects",
          align: "center",
          sortable: false,
          value: "total_prospects",
        },
        {
          text: "Outstanding Recommendations",
          align: "center",
          sortable: false,
          value: "new_prospects",
          width: 100,
        },
      ],
      items: [
        {
          name: "IDC 1",
          ranking: 1,
          sales: 55,
          active_advisors: 13,
          leader_percentage: 58,
          trend: "up",
          total_prospects: 342,
          new_prospects: 23,
        },
        {
          name: "IDC 2",
          ranking: 2,
          sales: 55,
          active_advisors: 13,
          leader_percentage: 58,
          trend: "down",
          total_prospects: 342,
          new_prospects: 23,
        },
        {
          name: "IDC 3",
          ranking: 3,
          sales: 55,
          active_advisors: 13,
          leader_percentage: 58,
          trend: "up",
          total_prospects: 342,
          new_prospects: 23,
        },
        {
          name: "IDC 4",
          ranking: 4,
          sales: 55,
          active_advisors: 13,
          leader_percentage: 58,
          trend: "up",
          total_prospects: 342,
          new_prospects: 23,
        },
        {
          name: "IDC 5",
          ranking: 5,
          sales: 55,
          active_advisors: 13,
          leader_percentage: 58,
          trend: "up",
          total_prospects: 342,
          new_prospects: 23,
        },
      ],
    },
    products: {
      items: [
        {
          title: "Product 1",
          type: "Life Insurance",
          image: "https://via.placeholder.com/800x600",
          excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          tags: ["Age", "Longevity", "Retirement Age"],
          active: false,
          action_text: "Recommendations",
        },
        {
          title: "PlanGap - North American",
          type: "Annuity",
          image: "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
          excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          tags: ["Income", "Savings"],
          active: false,
          action_text: "Recommendations",
        },
        {
          title: "Product 3",
          type: "Long Term Care",
          image: "https://via.placeholder.com/800x600",
          excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          tags: ["Life expectancy", "Long Term Care"],
          active: false,
          action_text: "Recommendations",
        },
        {
          title: "Product 4",
          type: "Long Term Care Insurance",
          image: "https://via.placeholder.com/800x600",
          excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          tags: ["Life expectancy", "Gender", "Long Term Care"],
          active: false,
          action_text: "Recommendations",
        },
      ],
    },
  },
];

export default orgConfig;
